import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PersonIcon from '@material-ui/icons/Person'
import WorkIcon from '@material-ui/icons/Work'
import { useHistory } from 'react-router-dom'
import { AuthContext, AuthStatus, IAuth } from '../contexts/authContext'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
})

const SwipeableTemporaryDrawer: React.FunctionComponent<{ open: boolean; setOpen: (open: boolean) => void }> = ({
  open,
  setOpen,
}) => {
  const { authStatus, signOut }: IAuth = useContext(AuthContext)

  const classes = useStyles()

  const history = useHistory()

  const redirectTo = (path: string): void => {
    history.push(path)
  }

  const signOutHandler = () => {
    signOut()
    redirectTo('/')
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    setOpen(open)
  }

  const list = (anchor: string) => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      {authStatus === AuthStatus.SignedIn ? (
        <List>
          {[
            { text: 'Plans', path: '/plans', icon: <DynamicFeedIcon /> },
            { text: 'Jobs', path: '/', icon: <WorkIcon /> },
            { text: 'Profile', path: '/profile', icon: <PersonIcon /> },
          ].map((item, index) => (
            <ListItem button key={item.text} onClick={() => redirectTo(item.path)}>
              <ListItemIcon> {item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          <ListItem button onClick={signOutHandler}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Sign Out'} />
          </ListItem>
        </List>
      ) : (
        <List>
          {[
            { text: 'Plans', path: '/plans', icon: <DynamicFeedIcon /> },
            { text: 'Jobs', path: '/', icon: <WorkIcon /> },
            { text: 'Sign In', path: '/signin', icon: <PersonIcon /> },
            { text: 'Signup', path: '/signup', icon: <PersonIcon /> },
          ].map((item, index) => (
            <ListItem button key={item.text} onClick={() => redirectTo(item.path)}>
              <ListItemIcon> {item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )

  return (
    <div>
      <SwipeableDrawer anchor={'left'} open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        {list('left')}
      </SwipeableDrawer>
    </div>
  )
}

export default SwipeableTemporaryDrawer
