import { IPlan } from '../interfaces/plan'

const plans = createPlans()

export default async function getPlans(url: string, id: string): Promise<IPlan[]> {
  try {
    // const res = await fetch(url, {
    //   method: 'GET',
    //   body: JSON.stringify({
    //     id: id,
    //   }),
    // })
    //
    // const data = await res.json()
    // return data

    return Promise.resolve(plans)
  } catch (e) {
    console.log(e)
    return Promise.resolve([])
  }
}

function createPlans() {
  return [
    {
      title: 'Basic Plan',
      description:
        'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      options: [
        {
          title: 'Weekly',
          price: 20,
          priceId: 'price_1HwSNpAb7dr8nOKo7dzfkpvj',
        },
        {
          title: 'Yearly',
          price: 30,
          priceId: 'price_1HwSNpAb7dr8nOKotULAr5e3',
        },
      ],
    },
    {
      title: 'Active Plan',
      description:
        'Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica',
      options: [
        {
          title: 'Weekly',
          price: 20,
          priceId: 'price_1HwSPmAb7dr8nOKoxKQ6PztD',
        },
        {
          title: 'Yearly',
          price: 30,
          priceId: 'price_1HwSPmAb7dr8nOKokZoX2pOx',
        },
      ],
    },
  ]
}
