import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Plan from '../components/plan'
import getPlans from '../actions/getPlans'
import { IPlan } from '../interfaces/plan'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
}))

const Plans = () => {
  const classes = useStyles()
  const [plans, setPlans] = useState<IPlan[]>([])

  useEffect(() => {
    async function fetchPlans() {
      const response = await getPlans('www.example.com', 'id')

      setPlans(response)
    }
    fetchPlans()
  }, [])

  return (
    <Layout>
      <>
        <Box m={4}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography className={classes.title} variant="h3">
              Plans
            </Typography>
          </Grid>
        </Box>
        <Box m={4} flexWrap="wrap" display="flex" alignItems={'center'} justifyContent={'center'}>
          {plans.map((plan) => (
            <Plan key={plan.title} plan={plan} />
          ))}
        </Box>
      </>
    </Layout>
  )
}

export default Plans
