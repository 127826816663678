import React from 'react'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

import { structureDate } from '../handlers'

import { IJob } from '../interfaces/job'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {},
  location: {},
  jobFields: {
    '& span': {
      fontWeight: 'bold',
    },
    whiteSpace: 'pre-wrap',
    padding: '15px',
    width: '100%'
  },
  button: {
    marginTop: '15px',
  },
  tag: {
    marginRight: '10px',
    fontSize: '1rem',
    color: theme.palette.primary.light
  },
  container: {
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: '10px',
    padding: '30px',
  },
}))

const JobCard: React.FunctionComponent<IJob> = ({ title, location, description, tags, contact, createdAt }) => {
  const classes = useStyles()

  const handleClick = (title: string): void => {}

  return (
    <Grid className={classes.container} container item>
      <Typography variant="h4" component="p" paragraph>
        {title}
      </Typography>
      <Typography className={classes.jobFields} variant="h6" component="div" color="primary" paragraph>
        <div>{structureDate(createdAt, 'DD MMM YYYY')}</div>
      </Typography>
      <Typography className={classes.jobFields} variant="h6" component="div" color="textPrimary" paragraph>
        <span>Location:</span> {location}
      </Typography>
      <Typography className={classes.jobFields} variant="h6" component="div" color="textPrimary" paragraph>
        <span>Description:</span> {description}
      </Typography>
      <Typography className={classes.jobFields} variant="h6" component="div" color="textPrimary" paragraph>
        <span>Contact:</span> {contact}
      </Typography>
      <Typography className={classes.jobFields} variant="h6" component="div" color="textPrimary" paragraph>
        <span>Tags:</span>{' '}
        {tags?.map((item: string) => (
          <Chip key={item} className={classes.tag} label={`#${item}`} onClick={() => handleClick(item)} />
        ))}
      </Typography>
    </Grid>
  )
}

export default JobCard
