import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import JobCard from '../components/jobCard'
import Layout from '../components/layout'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'

import getJob from '../actions/getJob'

import { IJob } from '../interfaces/job'
import { Box, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 20px 20px 0px',
  },
}))


const Job: React.FunctionComponent = () => {
  const [job, setJob] = useState<IJob>()
  const { id }: { id: string } = useParams()

  const classes = useStyles()

  useEffect(() => {
    const fetchJob = async () => {
      const response = await getJob(id)
      setJob(response)
    }
    fetchJob()
  }, [])

  return (
    <Layout>
      <>
        <Grid className={classes.root} container direction="column" alignItems="center" justify={'center'}>
          {job ? (
            <Grid xs={11} sm={6} lg={4} item>
              <Box>
                <Link className={classes.link} href={'/'} variant="h6">
                  <ArrowBackIcon /> Go To List
                </Link>
                <JobCard
                  id={job.id}
                  title={job.title}
                  location={job.location}
                  description={job.description}
                  tags={job.tags}
                  createdAt={job.createdAt}
                  contact={job.contact}
                />
                <Link className={classes.link} href={'/'} variant="h6">
                  <ArrowBackIcon /> Go To List
                </Link>
              </Box>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </>
    </Layout>
  )
}

export default Job
