import { API, graphqlOperation } from 'aws-amplify'
import { IJob, IJobResponse } from '../interfaces/job'
import * as queries from '../graphql/queries'

export class Job {
  public id: string
  public title: string
  public location: string
  public description: string
  public tags: string[]
  public createdAt: string
  public contact: string

  constructor(
    id: string,
    title: string,
    location: string,
    description: string,
    tags: string[],
    createdAt: string,
    contact: string
  ) {
    this.id = id
    this.title = title
    this.location = location
    this.description = description
    this.tags = tags
    this.createdAt = createdAt
    this.contact = contact
  }
}

export default async function getJob(id: string): Promise<IJob> {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(queries.getJob, {
        id: id,
      })
    )) as IJobResponse

    return data.getJob
  } catch (e) {
    console.log(e)
    throw Error(e)
  }
}
