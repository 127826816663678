import React from 'react'
import { useHistory } from 'react-router-dom'
import slugify from 'slugify'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import { structureDate } from '../handlers'
import { IJobPreview } from '../interfaces/job'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme) => ({
  table: {
  },
  head: {},
  th: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  row: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'pointer',
    },
  },
  link: {
    '&:hover': { textDecoration: 'none' },
    color: 'black',
  },
  button: {
    width: '100%',
  },
  buttonContainer: {
    maxWidth: '500px',
    margin: '40px auto'
  },
  tag: {
    margin: '4px',
    fontSize: '0.8rem',
    color: theme.palette.primary.light,
    height: '20px'
  },
  postDate: {
    color: theme.palette.primary.dark,
    fontSize: '0.6rem'
  }
}))

const titleToSlug = (title: string) => {
  return slugify(title, {
    lower: true,
  })
}

const JobsTable: React.FunctionComponent<{
  rows: IJobPreview[]
  loadMore: () => void
  disableLoadMore: boolean
}> = ({ rows, loadMore, disableLoadMore }) => {
  const classes = useStyles()

  const history = useHistory()

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell className={classes.th} align="left">
                Title
              </TableCell>
              <TableCell className={classes.th} align="left">
                Location
              </TableCell>
              <TableCell className={classes.th} align="left">
                Tags
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: IJobPreview, index) => (
              <TableRow
                // I will be changes with original id
                key={row.id}
                className={classes.row}
                onClick={() => history.push(`/job/${row.id}/${titleToSlug(row.location)}/${titleToSlug(row.title)}`)}>
                <TableCell align="left">
                  <div>
                    {row.title}
                  </div>
                  <div className={classes.postDate}>
                    {structureDate(row.createdAt, 'DD MMM YYYY')}
                  </div>
                </TableCell>
                <TableCell align="left">{row.location}</TableCell>
                <TableCell align="left">
                {row.tags?.map((item: string) => (
                  <Chip key={item} className={classes.tag} label={`#${item}`} />
                ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={5} className={classes.buttonContainer}>
        <Button
          disabled={disableLoadMore}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={loadMore}>
          Load More
        </Button>
      </Box>
    </>
  )
}

export default JobsTable
