import React from 'react'
import './App.css'

import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'

import SignIn from './routes/auth/signIn'
import SignUp from './routes/auth/signUp'
import VerifyCode from './routes/auth/verify'
import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import Jobs from './routes/jobs'
import Profile from './routes/profile'
import Job from './routes/job'
import Plans from './routes/plans'
import Success from './routes/success'
import Cancel from './routes/cancel'

ReactGA.initialize('UA-185930501-1');

let lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: 'rgb(128,0,128)',
    },
    background: {},
  },
  overrides: {
    MuiTableCell: {
        root: {  //This can be referred from Material UI API documentation. 
            padding: '4px 8px',
        },
    },
  },
})

const SignInRoute: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/verify" component={VerifyCode} />
      <Route path="/requestcode" component={RequestCode} />
      <Route path="/forgotpassword" component={ForgotPassword} />
    </Switch>
  </Router>
)

const MainRoute: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/profile" component={Profile} />
    </Switch>
  </Router>
)

const DefaultRoutes: React.FunctionComponent = () => (
  <Router>
    <Switch>
      <Route exact path="/" render={props => { ReactGA.pageview(props.location.pathname); return <Jobs />; }} />
      <Route exact path="/success" component={Success} />
      <Route exact path="/cancel" component={Cancel} />
      <Route path="/job/:id/:location/:slug" render={props => { ReactGA.pageview(props.location.pathname); return <Job />; }} />
      <Route path="/plans" component={Plans} />
    </Switch>
  </Router>
)

const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AuthProvider>
        <AuthIsSignedIn>
          <MainRoute />
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
          <SignInRoute />
        </AuthIsNotSignedIn>
        <DefaultRoutes />
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
