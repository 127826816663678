import React from 'react'

import Layout from '../components/layout'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import Lottie from 'react-lottie'
import * as animationData from '../assets/animations/eror.json'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

const defaultOptions = {
  loop: true,
  autoplay: true,
  // @ts-ignore
  animationData: animationData.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

const Cancel: React.FunctionComponent = () => {
  const classes = useStyles()
  return (
    <Layout>
      <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
        <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center">
          <Paper style={{ width: '100%', padding: 20 }}>
            <Lottie options={defaultOptions} height={200} width={200} />
            <Box m={2}>
              <Typography align={'center'} variant="h4">
                An eror happened during payment process
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Cancel
