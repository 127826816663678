import React from 'react'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Navbar from './navbar'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    flexWrap: 'initial'
  },
}))

const Layout: React.FunctionComponent<{ children: JSX.Element }> = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid className={classes.root} container direction="column" alignItems="stretch">
          {children}
        </Grid>
      </Grid>
    </>
  )
}

export default Layout
