import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import WorkIcon from '@material-ui/icons/Work';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableTemporaryDrawer from './drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const Navbar: React.FunctionComponent<{}> = () => {
  const classes = useStyles()
  const [toggle, setToggle] = useState(false)

  return (
    <>
      <AppBar position="static" color={'primary'}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setToggle(!toggle)}>
            <MenuIcon />
          </IconButton> */}
          <IconButton
            color="inherit"
            className={classes.menuButton}
            >
            <WorkIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            PurpleGigs.com
          </Typography>
        </Toolbar>
        <SwipeableTemporaryDrawer open={toggle} setOpen={setToggle} />
      </AppBar>
    </>
  )
}

export default Navbar
