import * as queries from '../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { IJob, IListJobsResponse } from '../interfaces/job'

export class Job {
  public id: string
  public title: string
  public location: string
  public description: string
  public tags: string[]
  public createdAt: string
  public contact: string

  constructor(
    id: string,
    title: string,
    location: string,
    description: string,
    tags: string[],
    createdAt: string,
    contact: string
  ) {
    this.id = id
    this.title = title
    this.location = location
    this.description = description
    this.tags = tags
    this.createdAt = createdAt
    this.contact = contact
  }
}

export default async function getJobs(nextToken: string, limit: number): Promise<IListJobsResponse> {
  try {
    let response;

    if (nextToken) {
      response = (await API.graphql(graphqlOperation(queries.listJobs, { limit, nextToken }))) as IListJobsResponse
    } else {
      response = (await API.graphql(graphqlOperation(queries.listJobs, { limit }))) as IListJobsResponse
    }

    return response;
  } catch (e) {
    console.log(e)
    throw Error(e)
  }
}
