import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import ReactGA from 'react-ga';

ReactGA.initialize('G-WDTGZ2NXSR');

Amplify.configure(awsconfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
