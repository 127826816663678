import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe(process.env.REACT_APP_PK_TEST as any)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 180,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
)

const Plan: React.FunctionComponent<{ plan: any }> = ({ plan }) => {
  const { options } = plan

  const classes = useStyles()

  console.log(process.env.REACT_APP_PK_TEST)

  const [index, setIndex] = React.useState<number>(0)
  const [option, setOption] = React.useState<any>(options[0])

  useEffect(() => {
    setOption(options[index])
  }, [index])

  const handleChange = (event: any) => {
    const value = event.target.value
    setIndex(value)
  }

  const handleClick = async () => {
    const stripe: any = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: option.priceId, // Replace with the ID of your price
          quantity: 1,
        },
      ],
      mode: 'subscription',
      successUrl: 'https://example.com/success',
      cancelUrl: 'https://example.com/cancel',
    })
  }

  return (
    <Box m={'10px'}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {plan.title}
            </Typography>
            <Typography variant="body2" color="primary" component="p" gutterBottom>
              {plan.description}
            </Typography>
            <Typography gutterBottom variant="h5" component="h3">
              {`${option.price}$`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-native-simple">Options</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" value={index} onChange={handleChange}>
              {options.map((option: any, index: number) => (
                <MenuItem value={index}>{option.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardActions>
        <CardActions>
          <Button variant="contained" color="primary" onClick={() => handleClick()}>
            Checkout
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

export default Plan
