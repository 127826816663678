import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import { useValidCode, useValidEmail, useValidPassword, useValidUsername } from '../../hooks/useAuthHooks'
import { Code, Email, Password, Username } from '../../components/authComponents'
import { AuthContext } from '../../contexts/authContext'
import Layout from '../../components/layout'

const useStyles = makeStyles({
  root: {
    height: '100vh',
  },
})

export default function ForgotPassword() {
  const classes = useStyles()

  const { code, setCode, codeIsValid } = useValidCode('')
  const { password, setPassword, passwordIsValid } = useValidPassword('')
  const { email, setEmail, emailIsValid } = useValidEmail('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('')

  const isValid =
    !codeIsValid ||
    code.length === 0 ||
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0 ||
    password !== passwordConfirm

  const history = useHistory()

  const authContext = useContext(AuthContext)

  const resetPassword = async () => {
    setLoading(true)
    try {
      await authContext.forgotPassword(email, code, password)
      setReset(true)
      setLoading(false)
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  const updatePassword = (
    <>
      <Box width="80%" m={1}>
        <Code codeIsValid={codeIsValid} setCode={setCode} />
      </Box>
      <Box width="80%" m={1}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Password" passwordIsValid={passwordIsValid} setPassword={setPassword} />
      </Box>
      <Box width="80%" m={1}>
        <Password label="Confirm Password" passwordIsValid={passwordConfirmIsValid} setPassword={setPasswordConfirm} />
      </Box>

      {/* Error */}
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box mt={2}>
        <Grid container direction="row" justify="center">
          <Box m={1}>
            <Button disabled={loading} onClick={() => history.goBack()} color="primary" variant="contained">
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid || loading} color="primary" variant="contained" onClick={resetPassword}>
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  )

  const passwordReset = (
    <>
      <Typography variant="h5">{`Password Reset`}</Typography>

      <Box m={4}>
        <Button onClick={() => history.push('/signin')} color="primary" variant="contained">
          Sign In
        </Button>
      </Box>
    </>
  )

  return (
    <Layout>
      <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
        <Grid xs={11} sm={6} lg={4} container direction="row" justify="center" alignItems="center" item>
          <Paper style={{ width: '100%', padding: 16 }}>
            <Grid container direction="column" justify="center" alignItems="center">
              {/* Title */}
              <Box m={3}>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Typography variant="h3">Forgot Password</Typography>
                </Grid>
              </Box>

              {!reset ? updatePassword : passwordReset}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}
