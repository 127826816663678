import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { CircularProgress } from '@material-ui/core'

import Layout from '../components/layout'
import JobsTable from '../components/jobsTable'

import getJobs from '../actions/getJobs'
import { IJobPreview } from '../interfaces/job'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
}))

const Jobs: React.FunctionComponent = () => {
  const [jobs, setJobs] = useState<IJobPreview[]>([])
  const [loading, setLoading] = useState(false)
  const [nextToken, setNextToken] = useState('');
  const [index, setIndex] = useState(0)
  const jobsOnPerLoad = 10

  const classes = useStyles()

  useEffect(() => {
    fetchJobs()
  }, [])

  const fetchJobs = async () => {
    setLoading(true)
    const response = await getJobs(nextToken, jobsOnPerLoad)
    const data = response.data;

    setNextToken(data.listJobs.nextToken);
    if (data.listJobs.items.length > 0) {
      const rows = [ ...jobs, ...data.listJobs.items ];
      setJobs(rows)
    }
    setLoading(false)
  }

  const loadMore = (): void => {
    fetchJobs();
  }

  return (
    <Layout>
      <>
        <Box m={4}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography color="primary" className={classes.title} variant="h6">
              Australian Software Development Jobs
            </Typography>
          </Grid>
        </Box>
        <Box m={4}>
          <JobsTable
            rows={jobs}
            loadMore={loadMore}
            disableLoadMore={!nextToken}
          />

          {loading ? (
            <CircularProgress />
          ) : (
            null
          )}
        </Box>
      </>
    </Layout>
  )
}

export default Jobs
