import React, { useContext } from 'react'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'

import { AuthContext } from '../contexts/authContext'
import Layout from '../components/layout'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  hero: {
    width: '100%',
    background: theme.palette.background.default,
  },
  logo: {
    fontSize: '70px',
  },
}))

export default function Profile() {
  const classes = useStyles()

  const history = useHistory()

  const auth = useContext(AuthContext)

  function signOutClicked() {
    auth.signOut()
    history.push('/')
  }

  function changePasswordClicked() {
    history.push('changepassword')
  }

  return (
    <Layout>
      <Box className={classes.hero} p={4}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Box m={2}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Box mr={3}>
                <BusinessCenterIcon className={classes.logo} />
              </Box>
              <Typography className={classes.title} variant="h3">
                Jobs Site
              </Typography>
            </Grid>
          </Box>
          <Box m={2}>
            <Button onClick={signOutClicked} variant="contained" color="primary">
              Sign Out
            </Button>
          </Box>
          <Box m={2}>
            <Button onClick={changePasswordClicked} variant="contained" color="primary">
              Change Password
            </Button>
          </Box>
        </Grid>
      </Box>
    </Layout>
  )
}
